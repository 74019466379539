<template>
    <section class="innovative-partnership">
        <Nav
            class="mt-5 mb-4"
            route-name="Partnership"
            parent-page-name="Партнерство"
            current-page-name="Инновационно-коммуникационное партнерство"
        />

        <div class="innovative-partnership__main-img d-none d-xl-flex">
            <div class="innovative-partnership__blurred-img mt-lg-auto ms-lg-auto d-flex flex-column">
                <h2 class="innovative-partnership__title">{{ getInnovativePartnershipPageInfo('title') }}</h2>
                <div class="innovative-partnership__text mt-3">
                    {{ getInnovativePartnershipPageInfo('text') }}
                </div>
                <img
                    src="../../assets/page-logo.svg"
                    class="innovative-partnership__logo ms-auto mt-lg-auto d-none d-xl-block"
                    alt=""
                    width="259"
                    height="72"
                >
            </div>
        </div>

        <div class="title-wrapper d-xl-none my-4 my-lg-5">
            <h2 class="educational-partnership__title educational-partnership__title--dark">
                {{ getInnovativePartnershipPageInfo('title') }}
            </h2>
            <div class="educational-partnership__text educational-partnership__text--dark">
                {{ getInnovativePartnershipPageInfo('text') }}
            </div>
        </div>

        <div class="innovative-partnership__cards d-flex flex-column flex-wrap flex-lg-row flex-lg-nowrap mt-5">
            <div class="innovative-partnership__card w-100">
                <div class="card__img d-flex justify-content-center">
                    <img
                        class="d-inline-block"
                        src="../../assets/views/innovative_partnership/img1.svg"
                        alt=""
                    >
                </div>

                <div class="card__text mt-4 pb-4">
                    {{ getInnovativePartnershipCardInfo(4, 'text') }}
                </div>
            </div>

            <div class="innovative-partnership__card w-100 ms-lg-4">
                <div class="card__img d-flex justify-content-center">
                    <img
                        class="d-inline-block"
                        src="../../assets/views/innovative_partnership/img2.svg"
                        alt=""
                    >
                </div>

                <div class="card__text mt-4 pb-4">
                    {{ getInnovativePartnershipCardInfo(5, 'text') }}
                </div>
            </div>

            <div class="innovative-partnership__card w-100 ms-lg-4">
                <div class="card__img d-flex justify-content-center">
                    <img
                        class="d-inline-block"
                        src="../../assets/views/innovative_partnership/img3.svg"
                        alt=""
                    >
                </div>

                <div class="card__text mt-4 pb-4">
                    {{ getInnovativePartnershipCardInfo(6, 'text') }}
                </div>
            </div>
        </div>

        <div class="innovative-partnership__tabs mt-5">
            <Tabs
                :items="tabItems"
                type="filled-text"
            >
                <template #content>
                    <div class="tab-content">
                        <!-- Консалтинг и услуги в области коммерциализации -->
                        <div class="tab-pane fade show active" id="accompaniment" role="tabpanel"
                             aria-labelledby="accompaniment-tab">
                            <div class="innovative-partnership__img2">
                                <div class="d-flex flex-column col-12 col-lg-7 accompaniment-cards-wrapper">
                                    <div class="accompaniment-cards d-flex flex-wrap">
                                        <div class="accompaniment-card px-4 pb-5 pt-2">
                                            <img
                                                src="../../assets/views/innovative_partnership/1.png"
                                                alt=""
                                                width="63"
                                                height="63"
                                                style="margin-left: 20px; margin-bottom: -4px"
                                            >
                                            <div class="accompaniment-card__text px-4 pb-4">
                                                {{ getWidgetInfo('consulting', 1) }}
                                            </div>
                                        </div>

                                        <div class="accompaniment-card px-4 pb-5 pt-2">
                                            <img
                                                src="../../assets/views/innovative_partnership/2.png"
                                                alt=""
                                                width="63"
                                                height="63"
                                                style="margin-left: 20px; margin-bottom: -4px"
                                            >
                                            <div class="accompaniment-card__text px-4 pb-4">
                                                {{ getWidgetInfo('consulting', 2) }}
                                            </div>
                                        </div>

                                        <div class="accompaniment-card px-4 pb-5 pt-2">
                                            <img
                                                src="../../assets/views/innovative_partnership/3.png"
                                                alt=""
                                                width="63"
                                                height="63"
                                                style="margin-left: 20px; margin-bottom: -4px"
                                            >
                                            <div class="accompaniment-card__text px-4 pb-4">
                                                {{ getWidgetInfo('consulting', 3) }}
                                            </div>
                                        </div>
                                      <div class="accompaniment-card px-4 pb-5 pt-2">
                                          <img
                                              src="../../assets/views/innovative_partnership/4.png"
                                              alt=""
                                              width="63"
                                              height="63"
                                              style="margin-left: 20px; margin-bottom: -4px"
                                          >
                                          <div class="accompaniment-card__text px-4 pb-4">
                                              {{ getWidgetInfo('consulting', 4) }}
                                          </div>
                                      </div>

                                      <div class="accompaniment-card px-4 pb-5 pt-2">
                                          <img
                                              src="../../assets/views/innovative_partnership/5.png"
                                              alt=""
                                              width="63"
                                              height="63"
                                              style="margin-left: 20px; margin-bottom: -4px"
                                          >
                                          <div class="accompaniment-card__text px-4 pb-4">
                                              {{ getWidgetInfo('consulting', 5) }}
                                          </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Правовое обеспечение трансфера технологий -->
                        <div class="tab-pane fade" id="consulting" role="tabpanel" aria-labelledby="consulting-tab">
                            <div class="innovative-partnership__img2">
                                <div class="d-flex flex-column col-12 col-lg-7 accompaniment-cards-wrapper">
                                    <div class="accompaniment-cards d-flex">
                                        <div class="accompaniment-card px-4 pb-5 pt-2">
                                            <img
                                                src="../../assets/views/innovative_partnership/1.png"
                                                alt=""
                                                width="63"
                                                height="63"
                                                style="margin-left: 20px; margin-bottom: -4px"
                                            >
                                            <div class="accompaniment-card__text px-4 pb-4">
                                                {{ getWidgetInfo('accompaniment', 1) }}
                                            </div>
                                        </div>

                                        <div class="accompaniment-card px-4 pb-5 pt-2">
                                            <img
                                                src="../../assets/views/innovative_partnership/2.png"
                                                alt=""
                                                width="63"
                                                height="63"
                                                style="margin-left: 20px; margin-bottom: -4px"
                                            >
                                            <div class="accompaniment-card__text px-4 pb-4">
                                                {{ getWidgetInfo('accompaniment', 2) }}
                                            </div>
                                        </div>

                                        <div class="accompaniment-card px-4 pb-5 pt-2">
                                            <img
                                                src="../../assets/views/innovative_partnership/3.png"
                                                alt=""
                                                width="63"
                                                height="63"
                                                style="margin-left: 20px; margin-bottom: -4px"
                                            >
                                            <div class="accompaniment-card__text px-4 pb-4">
                                                {{ getWidgetInfo('accompaniment', 3) }}
                                            </div>
                                        </div>

                                      <div class="accompaniment-card px-4 pb-5 pt-2">
                                        <img
                                            src="../../assets/views/innovative_partnership/4.png"
                                            alt=""
                                            width="63"
                                            height="63"
                                            style="margin-left: 20px; margin-bottom: -4px"
                                        >
                                        <div class="accompaniment-card__text px-4 pb-4">
                                          {{ getWidgetInfo('accompaniment', 4) }}
                                        </div>
                                      </div>

                                      <div class="accompaniment-card px-4 pb-5 pt-2">
                                        <img
                                            src="../../assets/views/innovative_partnership/5.png"
                                            alt=""
                                            width="63"
                                            height="63"
                                            style="margin-left: 20px; margin-bottom: -4px"
                                        >
                                        <div class="accompaniment-card__text px-4 pb-4">
                                          {{ getWidgetInfo('accompaniment', 5) }}
                                        </div>
                                      </div>

                                      <div class="accompaniment-card px-4 pb-5 pt-2">
                                        <img
                                            src="../../assets/views/innovative_partnership/6.png"
                                            alt=""
                                            width="63"
                                            height="63"
                                            style="margin-left: 20px; margin-bottom: -4px"
                                        >
                                        <div class="accompaniment-card__text px-4 pb-4">
                                          {{ getWidgetInfo('accompaniment', 6) }}
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Образование -->
                        <div class="tab-pane fade" id="financing" role="tabpanel" aria-labelledby="financing-tab">
                            <div class="innovative-partnership__img2">
                                <div class="d-flex flex-column col-12 col-lg-7 accompaniment-cards-wrapper">
                                    <div class="accompaniment-cards d-flex">
                                        <div class="accompaniment-card px-4 pb-5 pt-2">
                                            <img
                                                src="../../assets/views/innovative_partnership/1.png"
                                                alt=""
                                                width="63"
                                                height="63"
                                                style="margin-left: 20px; margin-bottom: -4px"
                                            >
                                            <div class="accompaniment-card__text px-4 pb-4">
                                                {{ getWidgetInfo('financing', 1) }}
                                            </div>
                                        </div>

                                        <div class="accompaniment-card px-4 pb-5 pt-2">
                                            <img
                                                src="../../assets/views/innovative_partnership/2.png"
                                                alt=""
                                                width="63"
                                                height="63"
                                                style="margin-left: 20px; margin-bottom: -4px"
                                            >
                                            <div class="accompaniment-card__text px-4 pb-4">
                                                {{ getWidgetInfo('financing', 2) }}
                                            </div>
                                        </div>

                                        <div class="accompaniment-card px-4 pb-5 pt-2">
                                            <img
                                                src="../../assets/views/innovative_partnership/3.png"
                                                alt=""
                                                width="63"
                                                height="63"
                                                style="margin-left: 20px; margin-bottom: -4px"
                                            >
                                            <div class="accompaniment-card__text px-4 pb-4">
                                                {{ getWidgetInfo('financing', 3) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </Tabs>
        </div>
    </section>
</template>

<script>
import {ref} from "vue";
import Tabs from "@/components/common/Tabs/Tabs";
import Nav from "@/components/Nav/Nav";
import {onMounted} from "vue";
import routerMixin from "@/mixins/routerMixin";
import {computed} from "vue";
import { useStore } from "vuex";

export default {
    name: "InnovativePartnership",

    components: {
        Tabs,
        Nav
    },

    setup() {
        const store = useStore()

        const tabItems = ref([
            {
                label: 'Консалтинг и услуги в области коммерциализации',
                tabId: 'accompaniment',
                active: true
            },
            {
                label: 'Правовое обеспечение трансфера технологий',
                tabId: 'consulting',
                active: false
            },
            {
                label: 'Образование',
                tabId: 'financing',
                active: false
            },
        ])

        const { isMenuVisible, toggleMenu } = routerMixin();

        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            document.body.scrollIntoView({ behavior: "smooth" });
        })

        store.dispatch('widgets/getAccompanimentConsultingFinancingWidgets')
        const widgets = computed(() => store.getters['widgets/accompanimentConsultingFinancingWidgets'])

        store.dispatch('pages/getInnovativePartnershipPage')
        const innovativePartnershipPage = computed(() => store.getters['pages/innovativePartnershipPage'])

        const getInnovativePartnershipPageInfo = (key) => {
            if (innovativePartnershipPage.value) {
                return innovativePartnershipPage.value[key]
            }

            return ''
        }

        const getInnovativePartnershipCardInfo = (id, key) => {
            if (innovativePartnershipPage.value) {
                return innovativePartnershipPage.value.cards.find(card => card.id === id)[key]
            }

            return ''
        }

        const getWidgetInfo = (key, order) => {
            if (widgets.value) {
                const widgetBlock = widgets.value[key];
                return widgetBlock.find(item => item.order === order).text
            }
        }

        return {
            tabItems,
            getInnovativePartnershipPageInfo,
            getInnovativePartnershipCardInfo,
            getWidgetInfo
        }
    }

}
</script>
